
import React, { useCallback, useEffect } from 'react'

import { InstantSearch, SearchBox, Hits, Highlight } from 'react-instantsearch-dom'
import algoliasearch from 'algoliasearch/lite'
import { useSearchAndSideNav } from '../../../../services/useSearchAndSideNav'

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const Hit = (props) => {
  const { search } = useSearchAndSideNav();

  const closeSearch = (e) => {
    search.set(false);
  }

  return (
    <a onClick={() => closeSearch()} href={props.hit.slug} className="hit-item">
      <div className="hit-title">{props.hit.title}</div>
      <div className="hit-description">
        <Highlight attribute="plainText" hit={props.hit} />
      </div>
    </a>
  )
}

const SearchModal = ({ searchActive, onClose }) => {
  const { search } = useSearchAndSideNav();

  const closeSearch = (e) => {
    search.set(false);
  }

  const escFunction = useCallback((event) => {
    if(event.keyCode === 27) {
      //Do whatever when esc is pressed
      closeSearch();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className={`search-modal-container ${searchActive ? 'active' : ''}`}>
      <div className="overlay" onClick={() => onClose()}></div>
      <div className="modal">
        <InstantSearch
          indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
          searchClient={searchClient}
        >
          <SearchBox autoFocus={true}/>
          <Hits 
            hitComponent={Hit}
            onClose={() => closeSearch()}
          />
        </InstantSearch>
      </div>
  </div>
  )
}

export default SearchModal;
