
import React from 'react'
import PropTypes from 'prop-types'
import "../styles/Button.sass"

const Button = ({ text, onClick }) => (
  <button 
    className="button"
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick()
    }}
  >
    {text}
  </button>
)

export default Button;