
import React from 'react'
import "../../../assets/styles/prism.css"
import "../styles/Layout.sass"
import SearchModal from '../../shared/search/components/SearchModal'
import { useSearchAndSideNav } from '../../../services/useSearchAndSideNav'
import { useAppSettings } from '../../../services/AppSettings'
import { Helmet } from 'react-helmet'

const ContentContainer = ({ children, sidenavActive }) => (
  <section 
    id="main-body" 
    className={`main-body ${sidenavActive ? 'sidenav-active' : 'sidenav-closed'}`}>
    <div className="content-container">
      <section 
        id="content-container" 
        className="content">
        {children}
      </section>
    </div>
  </section>
)

const Layout = ({ children }) => {
  const { darkMode } = useAppSettings();
  const { search, sidenav } = useSearchAndSideNav()

  return (
    <div className={`layout ${darkMode.active ? 'dark-mode' : ''}`}>
      <Helmet>
        {/* <noscript>
          <img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=909950680317843&ev=PageView&noscript=1"
          />
        </noscript> */}
      </Helmet>
      <div className="banner"></div>
      <SearchModal 
        searchActive={search.active} 
        onClose={() => search.set(false)}
      />
      
      <ContentContainer
        sidenavActive={sidenav.active}
      >
        {children}
      </ContentContainer>
      <link href="https://fonts.googleapis.com/css?family=Roboto:400,400i,500,700,900,900i&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css?family=Vollkorn:300,400,400i,600,700,700i&display=swap" rel="stylesheet"></link>
    </div>
  )
}

export default Layout